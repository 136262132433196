<template>
  <el-dialog
    title="개인정보 수집 및 이용에 대한 안내"
    :visible="dialogVisible"
    append-to-body
    width="1000px"
    @close="$emit('update:dialogVisible', false)"
  >
    <el-scrollbar wrap-style="max-height: calc(100vh - 300px);">
      <div class="privacy-cont">
        <p>
          (주)이모션 (이하 회사)는 당 사이트 이용자의 개인정보보호를 위해 최선의 조치를 취하고자
          노력하고 있으며, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련법령에서
          정보통신서비스제공자가 준수해야 할 개인정보보호에 관한 규정들을 충실히 이행하고 있습니다.
        </p>

        <p>
          회사는 당 개인정보취급방침을 통해 개인정보를 취급하는데 있어서 취해지는 조치들을
          알려드리며, 개인정보취급방침을 개정하는 경우에는 웹사이트 공지사항을 통하여 공지합니다.
        </p>

        <p>회사의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.</p>
        <br />
        <ol>
          <li>1. 수집하는 개인정보의 항목 및 수집방법</li>
          <li>2. 개인정보의 수집 및 이용목적</li>
          <li>3. 개인정보의 제3자 제공</li>
          <li>4. 개인정보의 보유 및 이용기간</li>
          <li>5. 개인정보의 파기절차 및 방법</li>
          <li>6. 개인정보주체의 권리와 그 행사방법</li>
          <li>7. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</li>
          <li>8. 개인정보의 기술적/관리적 보호 대책</li>
          <li>9. 개인정보 관리책임자 및 개인정보에 관한 민원서비스</li>
          <li>10. 링크 사이트에 대한 책임</li>
          <li>11. 기타 개인정보 취급에 관한 방침</li>
          <li>12. 개정이력</li>
        </ol>

        <h3>1. 수집하는 개인정보의 항목 및 수집방법</h3>

        <p class="sm">
          회사는 각종 지원과 관련한 문의사항 접수 및 응답 등을 위해 아래와 같은 개인정보를 수집하고
          있습니다.
        </p>

        <p class="sm"><strong>수집항목</strong></p>
        <ul class="sm">
          <li>– 필수 : 이름, 기관명, 이메일, 휴대전화번호</li>
          <li>– 선택 : 직위, URL사이트, 첨부파일</li>
        </ul>

        <p class="sm">개인정보 수집방법 : 홈페이지</p>

        <h3>2. 개인정보의 수집 및 이용목적</h3>

        <p class="sm">
          회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 서비스 제공, 콘텐츠 제공, 맞춤
          서비스 제공, 상담 및 컨설팅 운영 등 고지사항의 전달, 문의사항에 대한 응대 및 원활한
          의사소통의 경로 확보
        </p>

        <h3>3. 개인정보의 제3자 제공</h3>

        <p class="sm">
          회사는 이용자의 개인정보를 동의 없이 제3자에게 제공하지 않습니다. 다만, 고객의 개인정보를
          고객으로부터 동의 받거나 법령에 의해 허용된 범위 내에서 사용하며, 동 범위를 넘어
          이용하거나 제3자에게 제공하지 않습니다. 단, 이벤트 대행(당첨 안내, 경품 발송, 제세금 신고
          등)을 목적으로 고객의 동의 없이 재위탁 업체(주식회사, 젤라블루코리아)에 개인정보(성명,
          연락처)를 제공할 수 있습니다.
        </p>

        <h3>4. 개인정보의 보유 및 이용기간</h3>

        <p class="sm">
          회사는 「개인정보의 수집 및 이용목적」을 달성하기 전까지만 개인정보를 보유 및 이용하게
          됩니다. 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 「개인정보의 수집 및
          이용목적」이 달성되거나 이용기간이 종료된 경우 개인정보를 지체없이 파기합니다. 단, 다음의
          경우에 대해서는 각각 명시한 이유와 기간 동안 보존합니다.
        </p>

        <p class="sm"><strong>방문에 관한 기록</strong></p>
        <ul class="sm">
          <li>-보존 근거 : 통신비밀보호법</li>
          <li>-보존 기간 : 3개월</li>
        </ul>

        <h3>5. 개인정보의 파기절차 및 방법</h3>

        <p class="sm">
          회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이
          파기합니다. 파기절차 및 방법은 다음과 같습니다.
        </p>

        <p class="sm">
          <strong>파기절차</strong><br />
          이용자가 상담 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우
          별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 (개인정보의 보유 및
          이용기간) 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한
          경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
        </p>

        <p class="sm">
          <strong>파기방법</strong><br />
          – 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
          삭제합니다.<br />
          – 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        </p>

        <h3>6. 개인정보주체의 권리와 그 행사방법</h3>

        <ol class="sm">
          <li>
            ⑴ 이용자는 개인정보관리책임자에게 서면, 전화, 이메일 등을 통해 개인정보 수집에 대한
            동의를 철회할 수 있으며 그 경우 회사는 본인 여부를 확인한 후 법령에 다르게 규정하고 있는
            경우를 제외하고는 당해 개인정보의 파기 등 필요한 조치를 취합니다.
          </li>
          <li>
            ⑵ 이용자가 삭제를 원하는 경우, 개인정보관리담당자에게 서면, 전화 또는 이메일로 자신의
            개인정보에 대한 삭제를 요청할 수 있으며, 그 경우 회사는 필요한 조치를 취합니다.
          </li>
          <li>
            ⑶ 회사는 이용자의 대리인이 방문하여 열람 또는 정정요구를 하는 경우에는 이용자의 진정한
            대리인인지 여부를 확인하며 이 경우 회사는 대리관계를 나타내는 증표를 제시하도록 요구할
            수 있습니다.
          </li>
          <li>
            ⑷ 회사는 당해 개인정보의 전부 또는 일부에 대하여 열람 또는 개인정보 등에 대한 정정요구를
            거절할 정당한 이유가 있는 경우에는 이용자에게 이를 지체 없이 통지하고 그 이유를
            설명합니다.
          </li>
          <li>
            ⑸ 회사는 원활하고 안정적인 서비스 제공을 위하여 성명, 이메일 등 필수입력항목들의 변경에
            대해서는 해당 이용자에게 별도로 절차를 요청할 수 있습니다.
          </li>
        </ol>

        <h3>7. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h3>
        <ol class="sm">
          <li>
            ⑴ 회사는 이용자에게 특화된 맞춤서비스 등을 제공하기 위해서 이용자의 정보를 수시로
            저장하고 찾아내는 쿠키(Cookie, 접속정보파일)를 운용합니다. 회사는 쿠키 운용과 관련하여
            이용자의 컴퓨터는 식별하지만 이용자를 개인적으로 식별하지는 않습니다.
          </li>
          <li>
            ⑵ 이용자는 ‘쿠키’에 대한 선택권을 가지고 있습니다. 이용자는 웹브라우저에서
            [도구]>[인터넷옵션]>[보안]>[사용자정의수준]을 선택함으로써 모든 쿠키를 허용하거나,
            쿠키가 저장될 때 마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. 단,
            모든 쿠키의 저장을 거부하는 경우, 쿠키를 통해 회사에서 제공하는 서비스를 이용할 수
            없습니다.
          </li>
        </ol>

        <h3>8. 개인정보의 기술적/관리적 보호 대책</h3>

        <ol class="sm">
          <li>
            ⑴ 회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는
            훼손되지 않도록 안전성 확보를 위해 기술적 대책을 마련하고 있습니다.
          </li>
          <li>
            ⑵ 회원 개인정보는 암호화되어 있어 본인만 알 수 있으며, 개인정보 확인 및 변경도 본인에
            의해서만 가능합니다.
          </li>
          <li>
            ⑶ 회사는 암호화 통신을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고
            있습니다.
          </li>
          <li>
            ⑷ 회사는 해킹이나 컴퓨터바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을
            막기 위해 최선을 다하고 있습니다.
          </li>
          <li>
            ⑸ 회사는 만약의 사태를 대비하여 개인정보를 주기적으로 백업하고 있으며, 백신프로그램을
            이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다.
          </li>
          <li>
            ⑹ 회사는 시스템에 대한 접근통제, 권한관리, 취약점 점검 등의 조치를 통해 보안성이 강화될
            수 있도록 지속적으로 노력하고 있습니다.
          </li>
          <li>
            ⑺ 회사는 회원 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있으며, 개인정보를
            취급하는 직원을 대상으로 정기적인 교육을 실시하고 있습니다.
          </li>
          <li>
            ⑻ 회사는 개인정보보호 업무를 전담하는 부서에서 개인정보취급방침 및 내부 규정 준수여부를
            확인하여 문제가 발견될 경우, 즉시 바로 잡을 수 있도록 노력하고 있습니다.
          </li>
          <li>
            ⑼ 회원 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등의 개인정보가 유출되어 발생한
            문제에 대해서는 회사가 일체 책임을 지지 않습니다.
          </li>
        </ol>

        <h3>9. 개인정보 관리책임자 및 개인정보에 관한 민원서비스</h3>

        <p class="sm">
          회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이
          관련 부서 및 개인정보 관리책임자를 지정하고 있습니다.
        </p>

        <h3>[개인정보 관리책임자]</h3>

        <ul class="sm">
          <li>담당부서 : 경영전략본부</li>
          <li>개인정보 책임자 : 대표이사</li>
          <li>개인정보 책임자 이메일: gm@emotion.co.kr</li>
          <li>개인정보 책임자 연락처: 02-542-1987</li>
        </ul>
        <p class="sm">
          이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보
          관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해
          신속하게 충분한 답변을 드릴 것입니다.
        </p>

        <p class="sm">
          기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
        </p>
        <ul class="sm">
          <li>개인분쟁조정위원회 ( privacy.kisa.or.kr / 전화118)</li>
          <li>대검찰청 사이버수사과 (http://www.spo.go.kr / 전화02-3480-2000)</li>
          <li>경찰청 사이버테러대응센터 (www.ctrc.go.kr / 전화1566-0112)</li>
        </ul>

        <h3>10. 링크 사이트에 대한 책임</h3>

        <p class="sm">
          회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 그러나 링크 웹사이트들은
          회사의 개인정보취급방침이 적용되지 않으므로, 해당 링크를 통해 외부 웹사이트로 이동하시는
          경우, 해당 서비스의 정책을 검토하시기 바랍니다.
        </p>

        <h3>11. 기타 개인정보 취급에 관한 방침</h3>

        <p class="sm">
          본 개인정보취급방침은 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
          있을 시에는 변경되는 개인정보 취급방침을 시행하기 최소 7일 전에 회사 홈페이지(
          www.emotion.co.kr ) 을 통해 변경 이유 및 내용 등을 공지하도록 하겠습니다.
        </p>

        <h3>12. 개정이력</h3>

        <ul class="sm">
          <li>개인정보취급방침 버전 : 1.1</li>
          <li>개인정보취급방침 시행일 : 2016년 05월 02일</li>
          <li>개인정보취급방침 변경공고일 : 2016년 05월 02일</li>
        </ul>
      </div>
    </el-scrollbar>
  </el-dialog>
</template>

<script>
  export default {
    name: "privacy",
    props: ["dialogVisible"]
  };
</script>

<style lang="scss" scoped>
  .privacy-cont {
    color: #333333;
    font-size: 18px;
    &:before {
      content: "";
      display: block;
      height: 12px;
      width: 12px;
      border-radius: 6px;
      margin-bottom: 15px;
      background-color: $red;
    }
    word-break: keep-all;
    h3 {
      margin-top: 3em;
      line-height: 1.8em;
      font-weight: bold;
    }
    .sm {
      font-size: 14px;
    }
    p,
    ul,
    ol {
      margin-top: 0.5em;
      line-height: 1.8em;
    }
  }
</style>
